import styled, { css } from 'styled-components'

export const Container = styled.div<{active?: boolean, jaSelecionada?: boolean}>`
  cursor: pointer;
  font-size: .875rem;
  border-radius: 0.25rem;
  display: flex;
  background: white;
  border: 2px solid var(--Azul);
  padding: 1rem .75rem 1rem .75rem;
  width: 100%;
  height: 100%;

  h3{
    font-size: 1.125rem;
  }

  ${props => props.active && css`
     box-shadow: var(--SombraBackground);`
  }

  ${props => props.jaSelecionada
? css`
    border-color: var(--Green2);
    h4{
      color: var(--Green2);
    }
    svg{
      color: var(--Green2);
    }
    box-shadow: 0 0.25rem .5rem var(--AzulOpaco);
    font-weight: 600;
    cursor: not-allowed;
    opacity: .65;`
: props.active && css`
    border-color: var(--Rosa);
    h4{
      color: var(--Rosa);
    }
    svg{
      color: var(--Rosa);
    }
    box-shadow: 0 0.25rem .5rem var(--RosaOpaco);
    font-weight: 600;`}

  ${props => !props.active && css`
    svg{
      margin-right: .5rem;
      color: var(--Azul);
    }`
  }

  svg{
    margin-right: .5rem;
  }

  li:before {
    content: '';
    display: inline-block;
    margin-right: 3px;
    height: 10px; 
    margin-left: -8px; 
  }
  
  ${props => props.jaSelecionada
? ''
: `&:hover{
      h4{
          color: var(--Rosa);
      }
      svg{
          color: var(--Rosa);
      }
      background: var(--c1);
      border-color: var(--Rosa);
      font-weight: 600;
      box-shadow: 0 0.25rem .5rem var(--RosaOpaco);
  }`}
  
  `
