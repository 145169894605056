import { Flex, Grid, Text } from '@chakra-ui/react'
import { iPontoAtencaoPDI } from '../../../../../interfaces'

interface iProps {
  model: iPontoAtencaoPDI
}

export const CardDepartamentoPDI: React.FC<iProps> = ({ model }) => {
  return (
        <Flex width={'100%'} height={'100%'} boxShadow={'var(--SombraBackground)'} minH={'10rem'}>
            <Flex height={'100%'} alignItems={'center'} justifyContent='space-between' p={'1rem'} background={'var(--c2)'} >
                <Flex flexDir={'column'} gap={'.75rem'} fontSize={'.875rem'}>
                    <Flex flexDir='column'>
                        <Text fontWeight={700} fontSize={'1.25rem'}><strong>{model.competencia}</strong></Text>
                    </Flex>
                    <Flex flexDir='column'>
                        <Text fontWeight={400}>Competências dessa área que {model.status === 0 ? 'evoluíram' : 'pioraram'}</Text>
                        <Text fontWeight={'700'} fontSize={'.9325rem'}>{model.colaboradores}</Text>
                    </Flex>
                    <Flex flexDir='column'>
                        <Text fontWeight={400}>Média geral da área</Text>
                        <Text fontWeight={'700'} fontSize={'.9325rem'}>{model.porcentagem?.toFixed(2)}</Text>
                    </Flex>
                </Flex>
            </Flex>
            <Grid borderBottomRightRadius={'.325rem'} borderTopRightRadius={'.325rem'} padding={'1rem'} background={model.status === 0 ? 'var(--Green2)' : 'var(--Red)'} width={'-webkit-fill-available'}gridTemplateColumns={'repeat(3, 1fr)'} borderBottomLeftRadius={'.25rem'} borderTopLeftRadius={'.25rem'} pos={'relative'} gap={'1rem'}>
                {model.competencias?.map(e => {
                  return <Flex flexDir={'column'} gap={'.25rem'} alignItems={'center'}>
                    <Flex width={'3.5rem'} border={'2px solid var(--Azul)'} borderRadius={'50%'} background={'white'}>
                        <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={e.icone} />
                    </Flex>
                    <Text color={'white'} textShadow={'md'} fontSize={'.75rem'} wordBreak={'break-word'} textAlign={'center'} fontWeight={700}>{e.nome}</Text>
                </Flex>
                })}
            </Grid>
        </Flex>
  )
}
