/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { useEffect, useState } from 'react'
import { FaHeadset } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { Body } from '../../Layouts/Body'
import { PerfilColaborador } from '../../../components/PerfilColaborador/indesx'
import { iAvatarXInformacoes, iEtapaColaborador, iNineBoxAvaliacao, iPendencia, iPerfilColaborador } from '../../../interfaces'
import { appApi, urlSigah } from '../../../services/appApi'
import { CardEtapa } from './Components/CardEtapa'
import { ModalPesquisa } from './Components/ModalPesquisa'
import { ModalPrimeiroAcesso } from './Components/ModalPrimeiroAcesso'
import {
  MainColumn, MainContainer,
  PreviewEtapaContainer,
  HeadPreview
} from './styles'
import { WhiteContainer } from '../../../components/WhiteContainer'
import { Flex, Grid, Text } from '@chakra-ui/react'
import { Pesquisaimg } from './Svg/Pesquisaimg'
import { ContainerPesquisa } from './Components/ContainerPesquisa'
import { ModalChamadoRh } from './Components/ModalChamadoRh'
import { parseJwt } from '../../../services/token'
import { ModalVideoIntroducao } from './Components/ModalVideoIntroducao'
import { ButtomInicial } from './Components/ButtomInicial'
import { PropInvalida } from '../../../Utils/Helper'

import { iDadoTabelaAprovacao, TabelaAprovacao } from '../../RH/VisualizarTrilhaColaborador/Components/TabelaAprovacao'
import { iDadoTabela, Tabela } from '../../PDI/Dashboard/Individual/Components/Tabela'
import { CardReuniaoOne } from './Components/CardReuniaoOne'
import { iDadoTabelaDashboardPDI } from '../../PDI/Dashboard/Individual'
import { ModalPDITrilha } from './Components/ModalPDI'
import { ModalReuniao } from './Components/ModalReuniao'
import { ButtonCmp } from '../../../components/ButtonCmp'
import LoadingContainer from '../../../components/LoadingContainer'

interface iModelTrilha {
  id: string
  etapas: iEtapaColaborador[]
}

interface iConviteXPesquisa {
  tipo: number
  titulo: string
  link: string
  comunicacao?: iComunicacao
  dataInicio: string
  dataFim: string
}

interface iComunicacao {
  descricao: string
  uri: string
}

export interface iDadoReuniao {
  id: string
  tipo: string
  status: number
  dataInicio: string
  duracao: number
  assunto: string
  descricao: string
  colaborador: iAvatarXInformacoes
  link?: string
}

interface iPerfilPDI {
  pdisAlteracao: iDadoTabelaDashboardPDI[]
  pdisEmAndamento: iDadoTabelaDashboardPDI[]
  pdiAprovacao: iDadoTabelaAprovacao[]
  pdiParaSubir: iDadoTabelaDashboardPDI[]
}

interface iModel {
  convites: iConviteXPesquisa[]
  pendencias: iPendencia[]
  pdi: iPerfilPDI
  processo?: iModelTrilha
  pdiAndamento: iDadoTabela[]
}

export const Painel: React.FC = () => {
  const nav = useNavigate()
  const [Model, setModel] = useState<iModel>()
  const [Reunioes, setReunioes] = useState<iDadoReuniao[]>([])
  const [ModalAcesso, setModalAcesso] = useState(false)
  const [Colaborador, setColaborador] = useState<iPerfilColaborador>()
  const [ModalReuniaoIsOpen, setModalReuniaoIsOpen] = useState(false)
  const [ModalPDIIsOpen, setModalPDIIsOpen] = useState(false)
  const [ModalPesquisaIsOpen, setModalPesquisaIsOpen] = useState(false)
  const [ModalIntroducaoIsOpen, setModalIntroducaoIsOpen] = useState(false)
  const [ModalChamadoIsOpen, setModalChamadoIsOpen] = useState(false)
  const [ViewTrilha, setViewTrilha] = useState(false)
  const [ModelNineBox, setModelNineBox] = useState<iNineBoxAvaliacao>({
    comiteAtivo: false,
    nineBox: {
      id: '',
      padrao: false,
      areas: [],
      eixosX: [],
      eixosY: [],
      quadrantes: [],
      tags: [],
      squads: [],
      tipoMeta: 0,
      titulo: '',
      configuracaoEixoX: {
        descricao: '',
        titulo: ''
      },
      configuracaoEixoY: {
        descricao: '',
        titulo: ''
      }
    },
    comites: []
  })

  const [IsLoading, setIsLoading] = useState(false)
  const [IsLoadingPerfil, setIsLoadingPerfil] = useState(false)
  const [ExpandirReunioes, setExpandirReunioes] = useState(false)

  const [Etapa, setEtapa] = useState<iEtapaColaborador>()

  function getTrilha(): void {
    setIsLoading(true)
    appApi.get('ProcessoOnboard/Trilha/v3').then(response => {
      if (!PropInvalida(response.data)) {
        setModel(response.data)
      }
      setIsLoading(false)
    }).catch(err => console.log(err))
  }

  function onClickEtapa(obj: iEtapaColaborador): any {
    if ((obj.tipo !== 2 && obj.tipo !== 7) && (obj.status !== 0 && obj.status !== 2 && obj.status !== 6 && obj.status !== 7)) {
      return () => { }
    }
    setEtapa(obj)
    if (obj.tipo === 1) {
      return nav(`/Colaborador/Integracao/${obj.interacoes[0].idExterno}/Etapa/${obj.id}`)
    } else if (obj.tipo === 3) {
      if (obj.status === 2) {
        return nav(`/Colaborador/Imersao/${obj.interacoes[0].idExterno}/Etapa/${obj.id}/Visualizar`)
      } else {
        return nav(`/Colaborador/Imersao/${obj.interacoes[0].idExterno}/Etapa/${obj.id}`)
      }
    } else if (obj.tipo === 4 || obj.tipo === 5) {
      return setModalPesquisaIsOpen(true)
    } else if (obj.tipo === 6) {
      return window.open(`${urlSigah}/PesquisasPEC/f/${obj.conviteId}`, '_blank')
    } else if (obj.tipo === 2 || obj.tipo === 7 || obj.tipo === 10) {
      return setModalReuniaoIsOpen(true)
    } else if (obj.tipo === 9) {
      return setModalPDIIsOpen(true)
    } else {
      return () => { }
    }
  }

  function getColaboador(): void {
    setIsLoadingPerfil(true)
    appApi.get('Colaborador/Perfil')
      .then(response => { setColaborador(response.data); ObterNineBox(response.data.id) })
      .catch(err => console.log(err))
      .finally(() => setIsLoadingPerfil(false))
  }

  function getReunioes(): void {
    appApi.get('ReuniaoOne/Principal')
      .then(res => {
        if (res.status === 200) {
          setReunioes(res.data)
        }
      })
      .catch(err => console.log(err))
  }

  function Load(): void {
    getTrilha()
    getColaboador()
  }

  function PostChamado(texto: string): void {
    const form = {
      texto: texto
    }
    appApi.post('Chamado', form)
      .then(res => {
        setModalChamadoIsOpen(false)
        console.log(res.data)
      }
      ).catch(err => console.log(err))
  }

  function ConcluirPrimeiroAcesso(pronome: string, avatar?: string): void {
    const form = {
      avatar: avatar,
      pronome: pronome
    }

    appApi.patch('EtapaColaborador/PrimeiroAcesso', form)
      .then(res => {
        localStorage.setItem('token', res.data)
        if (Model?.processo) {
          getTrilha()
        }
        Load()
      })
      .catch(err => console.log(err))
  }

  function onCloseModalAcesso(): void {
    setModalAcesso(false)
    setModalIntroducaoIsOpen(true)
  }

  function TemAtividades(): boolean {
    return !(Model?.pendencias.every(r => r.nomePendencia === 'Não há atividades disponíveis no momento') &&
      Model?.pdi?.pdiAprovacao?.length === 0 &&
      Model?.pdi?.pdisEmAndamento?.length === 0 &&
      Reunioes.length === 0 &&
      Model.convites.length === 0)
  }

  function ObterNineBox(colabId: string): void {
    appApi.get(`NineBox/Colaborador/${colabId ?? ''}`)
      .then(res => setModelNineBox(res.data))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    Load()
    getReunioes()
    const val = parseJwt().pa
    if (val === '' || val === 'False') {
      setModalAcesso(true)
    }
  }, [])

  return (
    <Body>
      {ModalReuniaoIsOpen &&
        <ModalReuniao
          onRequestClose={() => setModalReuniaoIsOpen(false)}
          etapa={Etapa}
        />
      }

      {ModalPesquisaIsOpen &&
        <ModalPesquisa
          onRequestClose={() => setModalPesquisaIsOpen(false)}
          etapa={Etapa}
        />
      }

      {ModalPDIIsOpen &&
        <ModalPDITrilha
          onRequestClose={() => setModalPDIIsOpen(false)}
          etapa={Etapa}
        />
      }

      {ModalChamadoIsOpen &&
        <ModalChamadoRh
          isOpen
          onConfirm={PostChamado}
          onRequestClose={() => setModalChamadoIsOpen(false)}
        />
      }

      {ModalAcesso &&
        <ModalPrimeiroAcesso
          isOpen
          OnCloseModal={onCloseModalAcesso}
          onConcluir={ConcluirPrimeiroAcesso}
        />
      }

      {ModalIntroducaoIsOpen &&
        <ModalVideoIntroducao
          isOpen
          onClose={() => setModalIntroducaoIsOpen(false)}
        />
      }

      <Flex>
        <PerfilColaborador
          Colaborador={Colaborador ?? {} as iPerfilColaborador}
          margin='0 1rem 0 0'
          reload={getColaboador}
          nineBox={ModelNineBox.nineBox}
          IsLoading={IsLoadingPerfil}
        />
        {!ViewTrilha && <MainContainer>
          <LoadingContainer loading={IsLoading} linhas={26}>
            {Model && (
              <>
                {!(Model?.pendencias.every(r => r.nomePendencia === 'Não há atividades disponíveis no momento')) &&
                  <WhiteContainer>
                    <Grid templateColumns='repeat(2, 1fr)' gap={'.625rem'}>
                      {
                        Model?.pendencias.map((e, i) => {
                          if (e.nomePendencia !== 'Não há atividades disponíveis no momento') {
                            return (
                              <ButtomInicial
                                key={i}
                                pendencia={e}
                                clickExterno={() => setViewTrilha(true)}
                              />
                            )
                          }
                          return null
                        })
                      }
                    </Grid>
                  </WhiteContainer>
                }

                {!TemAtividades() && <WhiteContainer
                  styles={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Flex fontWeight={'600'} minH={'24rem'} justifyContent={'center'} alignItems={'center'}><h3>Sem atividades disponíveis no momento!</h3></Flex>
                </WhiteContainer>
                }

                {Model?.pdi?.pdiAprovacao?.length > 0 && (
                  <WhiteContainer>
                    <h3>PDI aguardando aprovação</h3>
                    <Flex marginTop={'.5rem'} flexDir={'column'}>
                      <TabelaAprovacao
                        slice={4}
                        dados={Model?.pdi.pdiAprovacao}
                      />
                    </Flex>
                  </WhiteContainer>
                )}

                {Model.pdi?.pdisEmAndamento?.length > 0 && (
                  <WhiteContainer>
                    <Tabela
                      iconSize='1.8rem'
                      fontSize='14px'
                      slice={4}
                      dados={Model.pdi.pdisEmAndamento}
                      modoHome = {true}
                    />
                  </WhiteContainer>
                )}

                {Model.pdi?.pdisAlteracao?.length > 0 && (
                  <WhiteContainer>
                    <Tabela
                      iconSize='1.8rem'
                      fontSize='12px'
                      slice={4}
                      dados={Model.pdi.pdisAlteracao}
                      variant='editar'
                    />
                  </WhiteContainer>
                )}

                {Model.pdi?.pdiParaSubir?.length > 0 && (
                  <WhiteContainer>
                    <Tabela
                      iconSize='1.8rem'
                      fontSize='12px'
                      slice={4}
                      dados={Model.pdi.pdiParaSubir}
                      variant='criar'
                    />
                  </WhiteContainer>
                )}

                {Reunioes.length > 0 &&
                  <WhiteContainer>
                    <h3>Reuniões 1:1</h3>

                    <Flex mt={'1rem'} gap={'.75rem'} flexDir={'column'}>
                      {Reunioes.filter(e => e.status === 0).length > 0 &&
                        <Flex flexDir={'column'} gap={'.25rem'}>
                          <Text fontWeight={'bold'}>Convites pendentes</Text>
                          <Flex flexDir={'column'} gap={'.75rem'}>
                            {Reunioes.filter(e => e.status === 0).map((e, i) => {
                              return (
                                <CardReuniaoOne key={i} model={e} reload={getReunioes} />
                              )
                            })}
                          </Flex>
                        </Flex>
                      }

                      {Reunioes.filter(e => e.status === 1).length > 0 &&
                        <Flex flexDir={'column'} gap={'.25rem'}>
                          <Text fontWeight={'bold'}>Em andamento</Text>
                          <Flex flexDir={'column'} gap={'.75rem'}>
                            {Reunioes.filter(e => e.status === 1).map((e, i) => {
                              return (
                                <CardReuniaoOne key={i} model={e} reload={getReunioes} />
                              )
                            })}
                          </Flex>
                        </Flex>
                      }

                      {Reunioes.filter(e => e.status === 2).length > 0 &&
                        <Flex flexDir={'column'} gap={'.25rem'}>
                          <Text fontWeight={'bold'}>Atrasadas</Text>
                          <Flex flexDir={'column'} gap={'.75rem'}>
                            {Reunioes.filter(e => e.status === 2).map((e, i) => {
                              return (
                                <CardReuniaoOne key={i} model={e} reload={getReunioes} />
                              )
                            })}
                          </Flex>
                        </Flex>
                      }

                      {Reunioes.filter(e => e.status === 3).length > 0 &&
                        <Flex flexDir={'column'} gap={'.25rem'}>
                          <Text fontWeight={'bold'}>Próximas reuniões</Text>
                          <Flex flexDir={'column'} gap={'.75rem'}>
                            {Reunioes.filter(e => e.status === 3).map((e, i) => {
                              if (!ExpandirReunioes && i > 1) return null

                              return (
                                <CardReuniaoOne key={i} model={e} reload={getReunioes} />
                              )
                            })}
                          </Flex>

                          {!ExpandirReunioes && Reunioes.filter(e => e.status === 3).length > 2 &&
                            <Text
                              cursor={'pointer'}
                              onClick={() => setExpandirReunioes(true)}
                              fontWeight={'700'}
                              textDecoration='underline'
                              color={'var(--Azul)'}
                            >Mostrar todas</Text>
                          }

                          {ExpandirReunioes && Reunioes.filter(e => e.status === 3).length > 2 &&
                            <Text
                              cursor={'pointer'}
                              onClick={() => setExpandirReunioes(false)}
                              fontWeight={'700'}
                              textDecoration='underline'
                              color={'var(--Azul)'}
                            >Ocultar</Text>
                          }
                        </Flex>
                      }

                      {Reunioes.filter(e => e.status === 4).length > 0 &&
                        <Flex flexDir={'column'} gap={'.25rem'}>
                          <Text fontWeight={'bold'}>Pendentes para configuração</Text>
                          <Flex flexDir={'column'} gap={'.75rem'}>
                            {Reunioes.filter(e => e.status === 4).map((e, i) => {
                              return (
                                <CardReuniaoOne key={i} model={e} reload={getReunioes} />
                              )
                            })}
                          </Flex>
                        </Flex>
                      }
                    </Flex>
                  </WhiteContainer>
                }

                {Model?.processo && (
                  <WhiteContainer>
                    <HeadPreview>
                      <Pesquisaimg />
                      <span>Você tem uma etapa a ser realizada...</span>
                    </HeadPreview>
                    <PreviewEtapaContainer>
                      {
                        Model.processo.etapas.filter(e => e.status !== 2).slice(0, 2).map((e: iEtapaColaborador, i) => {
                          return (
                            <CardEtapa
                              key={i}
                              etapa={e}
                              last={true}
                              onClick={() => onClickEtapa(e)}
                            />
                          )
                        })
                      }
                    </PreviewEtapaContainer>
                    <ButtonCmp
                      OutlineColor='Green2'
                      mt={'1rem'}
                      onClick={() => setViewTrilha(true)}
                    >Clique e veja toda sua linha do tempo</ButtonCmp>
                  </WhiteContainer>
                )}

                {Model.convites.length > 0 &&
                  <Grid gridTemplateColumns={'repeat(2, 1fr)'} gap={'1rem 1rem'}>
                    {Model.convites?.map((e, i) => {
                      return (
                        <WhiteContainer>
                          <ContainerPesquisa key={i} convite={e} />
                        </WhiteContainer>
                      )
                    })}
                  </Grid>
                }

              </>
            )}
            </LoadingContainer>
          </MainContainer>}
          {ViewTrilha && (
            <MainColumn padding='1rem 1rem' width='60%'>
              <Flex justifyContent={'space-between'} alignItems={'center'} mb={'1rem'}>
                <Flex flexDir={'column'}>
                  <h3>Trilha em andamento</h3>
                  <span style={{ fontSize: '.875rem', lineHeight: '.875rem' }}>Acompanhe cada etapa a ser realizada e que você já realizou</span>
                </Flex>
                <Flex gap={'.5rem'}>
                  <ButtonCmp VarColor='c5' onClick={() => setViewTrilha(false)}>Voltar</ButtonCmp>
                  <ButtonCmp onClick={() => setModalChamadoIsOpen(true)} VarColor='Green2' leftIcon={<FaHeadset/>}> Abrir chamado com RH</ButtonCmp>
                </Flex>
              </Flex>
              {
                (Model?.processo) && (
                  <Flex flexDir={'column'}>
                    {
                      Model?.processo.etapas.map((e: iEtapaColaborador, i) => {
                        return (
                          <CardEtapa
                            key={i}
                            etapa={e}
                            last={i + 1 === Model?.processo?.etapas?.length ?? 0}
                            onClick={() => onClickEtapa(e)}
                          />
                        )
                      })
                    }
                  </Flex>
                )
              }
            </MainColumn>
          )}
      </Flex>
    </Body>
  )
}
