import { Box, Flex, FormControl, useToast, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Grid, Text, Link, RadioGroup, Radio, Stack } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { WhiteContainer } from '../../../../components/WhiteContainer'
import { Body } from '../../../Layouts/Body'
import { appApi } from '../../../../services/appApi'
import { FaPencilAlt, FaPlus, FaSave, FaUsers } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { PropInvalida, UpdateElementByPosition } from '../../../../Utils/Helper'
import { ModalCrop } from './components/ModalCrop'
import { ItemNineBox } from './components/ItemNineBox'
import { iNomeId } from '../../../../interfaces'
import { MultiColaborador } from '../../../../components/MultiColaborador'
import { Tag } from './components/Tag'
import { Legenda } from '../../../Jornada/ReuniaoOne/Configuracao/styles'
import { useAuth } from '../../../../contexts/AuthContext'
import { ModalDelete } from '../../../../components/ModalDelete'
import { InputCmp } from '../../../../components/InputCmp'
import { CheckboxCmp } from '../../../../components/CheckboxCmp'

export interface iPutModel {
  chaveApiPi?: string
  empresa: iPutEmpresa
  responsaveis: iResponsavel[]
  nineBoxConfig: NineBoxConfig
  confiPesquisaPercepcao: iConfiPesquisaPercepcao
  configTrilha: iConfigTrilha
  configTeams: iConfigTeams
  configZoom: iConfigZoom
  configReuniao: iConfigReuniao
  configPDI: iConfigPDI
}

export interface iPutEmpresa extends Omit<iEmpresa, 'areas'> { }

export interface NineBoxConfig extends Omit<iNineBoxConfig, 'boxes'> { }

export interface iModel {
  chaveApiPi?: string
  empresa: iEmpresa
  responsaveis: iResponsavel[]
  contrato: string
  nineBoxConfig: iNineBoxConfig
  confiPesquisaPercepcao: iConfiPesquisaPercepcao
  configTrilha: iConfigTrilha
  configTeams: iConfigTeams
  configZoom: iConfigZoom
  configReuniao: iConfigReuniao
  configPDI: iConfigPDI
}

export interface iEmpresa {
  nome: string
  logo: string
  email: string
  telefone: string
  segmento: string
  numeroFuncionarios: number
  areas: iArea[]
}

export interface iArea {
  areaId: string
  nome: string
  qtd: number
}

export interface iResponsavel {
  tipoResponsabilidade: number
  colaboradorId: string
}

export interface iNineBoxConfig {
  liberarBoxGestor: boolean
  tipoMatriz: number
  boxes: iBox[]
}

export interface iBox {
  id: string
  titulo: string
  tags: string[]
  padrao: boolean
}

export interface iConfiPesquisaPercepcao {
  pares: number
  liderados: number
  outros: number
  altaLideranca: number
  comentariosResultado: number
  liberarResultado: boolean
  monitorarDevolutiva?: boolean
  assinaturaGestor: boolean
  assinaturaColaborador: boolean
  textoConclusao: boolean
  comentarios: boolean
}

export interface iConfigTrilha {
  adicionarEtapaLiderado: boolean
  concluirEtapaLiderado: boolean
  editarEtapaLiderado: boolean
}

export interface iConfigPDI {
  colaboradorFazerProprioPDI: boolean
  gestorAtribuirPDI: boolean
}

export interface iConfigReuniao {
  responsaveisAdicionarTipo: number[]
  tipoListaParticipante: number[]
  formularioFeedback: number[]
}

interface iConfigTeams {
  tenantId: string
  clientId: string
  clientSecret: string
}

interface iConfigZoom {
  accountId: string
  clientId: string
  clientSecret: string
  email: string
}

export const ConfiguracaoEmpresa: React.FC = () => {
  const { recarregarPermissoes } = useAuth()
  const toast = useToast()
  const nav = useNavigate()
  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])
  const [Tipos, setTipos] = useState<iNomeId[]>([])
  const [IsLoading, setIsLoading] = useState(false)

  const [ZoomIsIvalid, setZoomIsInvalid] = useState(false)
  const [TeamsIsIvalid, setTeamsIsInvalid] = useState(false)

  const [Model, setModel] = useState<iModel>({
    responsaveis: [],
    empresa: {
      areas: [],
      email: '',
      logo: '',
      nome: '',
      numeroFuncionarios: 0,
      segmento: '',
      telefone: ''
    },
    contrato: '',
    nineBoxConfig: {
      boxes: [],
      liberarBoxGestor: false,
      tipoMatriz: 0
    },
    configTrilha: {
      adicionarEtapaLiderado: false,
      concluirEtapaLiderado: false,
      editarEtapaLiderado: false
    },
    confiPesquisaPercepcao: {
      altaLideranca: 0,
      liderados: 0,
      outros: 0,
      pares: 0,
      liberarResultado: false,
      assinaturaColaborador: false,
      assinaturaGestor: false,
      comentarios: false,
      textoConclusao: true,
      comentariosResultado: 0
    },
    configTeams: {
      clientId: '',
      clientSecret: '',
      tenantId: ''
    },
    configZoom: {
      accountId: '',
      clientId: '',
      clientSecret: '',
      email: ''
    },
    configReuniao: {
      responsaveisAdicionarTipo: [],
      tipoListaParticipante: [],
      formularioFeedback: []
    },
    configPDI: {
      colaboradorFazerProprioPDI: false,
      gestorAtribuirPDI: false
    }
  })
  const [ModalCropIsOpen, setModalCropIsOpen] = useState(false)

  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [Id, setId] = useState<string>()

  function ValidarMeeting(UpdateModel: iPutModel): boolean {
    if
    (
      UpdateModel.configZoom !== undefined &&
      (
        !PropInvalida(UpdateModel.configZoom.accountId) ||
        !PropInvalida(UpdateModel.configZoom.clientId) ||
        !PropInvalida(UpdateModel.configZoom.clientSecret)
      ) &&
      PropInvalida(UpdateModel.configZoom.accountId, UpdateModel.configZoom.clientId, UpdateModel.configZoom.clientSecret)
    ) {
      setZoomIsInvalid(true)
      toast({
        title: 'Preencha todos os campos obrigatórios do Zoom!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    } else if
    (
      UpdateModel.configTeams !== undefined &&
      (
        !PropInvalida(UpdateModel.configTeams.tenantId) ||
        !PropInvalida(UpdateModel.configTeams.clientId) ||
        !PropInvalida(UpdateModel.configTeams.clientSecret)
      ) &&
      PropInvalida(UpdateModel.configTeams.tenantId, UpdateModel.configTeams.clientId, UpdateModel.configTeams.clientSecret)
    ) {
      setTeamsIsInvalid(true)
      toast({
        title: 'Preencha todos os campos obrigatórios do Teams!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }
    return true
  }

  function AtualizarConfiguracao(): void {
    const UpdateModel: iPutModel = {
      chaveApiPi: Model.chaveApiPi ?? '',
      configTrilha: Model.configTrilha,
      responsaveis: Model.responsaveis,
      nineBoxConfig: Model.nineBoxConfig,
      empresa: Model.empresa,
      confiPesquisaPercepcao: Model.confiPesquisaPercepcao,
      configReuniao: Model.configReuniao,
      configTeams: {
        clientId: Model.configTeams.clientId ?? '',
        clientSecret: Model.configTeams.clientSecret ?? '',
        tenantId: Model.configTeams.tenantId ?? ''
      },
      configZoom: {
        accountId: Model.configZoom.accountId ?? '',
        clientId: Model.configZoom.clientId ?? '',
        clientSecret: Model.configZoom.clientSecret ?? '',
        email: Model.configZoom.email ?? ''
      },
      configPDI: {
        colaboradorFazerProprioPDI: Model.configPDI?.colaboradorFazerProprioPDI ?? false,
        gestorAtribuirPDI: Model.configPDI?.gestorAtribuirPDI ?? false
      }
    }
    if (!ValidarMeeting(UpdateModel)) { return }

    setTeamsIsInvalid(false)
    setZoomIsInvalid(false)
    setIsLoading(true)
    appApi.put('Empresa/Configuracao', UpdateModel)
      .then(() => {
        setIsLoading(false)
        toast({
          title: 'Configuração atualizada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        recarregarPermissoes()
      })
      .catch(({ response }) => {
        console.log(response)
        setIsLoading(false)
        if (response.data?.toLowerCase().includes('teams')) {
          setTeamsIsInvalid(true)
        } else if (response.data?.toLowerCase().includes('zoom')) {
          setZoomIsInvalid(true)
        }

        toast({
          title: response.data,
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      })
  }

  function getConfig(): void {
    appApi.get('Empresa/Configuracao')
      .then(res => setModel(res.data))
      .catch(err => console.log(err))
  }

  function getColaboradores(): void {
    appApi.get('Colaborador/Select')
      .then(res => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  function ObterTipos(): void {
    appApi.get('TipoXEmpresa/Select?tipo=2')
      .then(res => {
        setTipos(res.data)
      })
      .catch(err => console.log(err))
  }

  function AtualizarTipo(tipo: iNomeId): void {
    appApi.put(`TipoXEmpresa/${tipo.id}`, {
      texto: tipo.nome,
      tipo: 2
    })
      .then(() => {
        toast({
          title: 'Tipo atualizado com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        const pos = Tipos.findIndex(e => e.id === tipo.id)
        if (pos !== -1) {
          setTipos(UpdateElementByPosition([...Tipos], pos, tipo))
        }
      })
      .catch(err => console.log(err))
  }

  function DeletarTipo(): void {
    appApi.delete(`TipoXEmpresa/${Id}`)
      .then(() => {
        toast({
          title: 'Tipo excluído com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setTipos([...Tipos.filter(e => e.id !== Id)])
      })
      .catch(err => console.log(err))
  }

  function onOpenModalDelete(id: string): void {
    setModalDeleteIsOpen(true)
    setId(id)
  }

  function onCloseModalDelete(): void {
    setModalDeleteIsOpen(false)
    setId(undefined)
  }

  function onDeleteResponsavel(tipo: number, id: string): void {
    setModel({
      ...Model, responsaveis: [...Model.responsaveis.filter(w => !(w.tipoResponsabilidade === tipo && w.colaboradorId === id))]
    })
  }

  useEffect(() => {
    getConfig()
    getColaboradores()
    ObterTipos()
  }, [])

  return (
    <Body>
      {ModalCropIsOpen && (
        <ModalCrop
          isOpen={ModalCropIsOpen}
          onRequestClose={() => setModalCropIsOpen(false)}
        />
      )}

      {ModalDeleteIsOpen &&
        <ModalDelete
          isOpen
          message='Tem certeza que deseja excluir esse tipo?'
          center
          onConfirm={DeletarTipo}
          onRequestClose={onCloseModalDelete}
        />
      }

      <WhiteContainer margin='.5rem 0 0 0'>
        <Flex marginBottom={'1rem'} justifyContent='space-between'>
          <h2>Configuração da empresa</h2>
          <ButtonCmp VarColor='Rosa' onClick={() => nav('/PrimeiroAcesso')}>Primeiro acesso</ButtonCmp>
        </Flex>
        <Flex alignItems={'center'} flexDir={'column'} width={'100%'}>
          {Model?.empresa.logo !== 'https://sigah.arquiteturahumana.com.br/css/logoBranco.png' && <Box bg={'var(--c6)'} display={'flex'} alignItems={'center'} borderRadius={'.25rem'} p={'.125rem'} margin={'1rem 0'}>
            <img title={Model?.empresa.nome} src={Model?.empresa.logo} />
          </Box>}
        </Flex>
        <Flex marginBottom={'.25rem'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
          <h3>{Model?.empresa.nome}</h3>
          <Text fontWeight='400'>{Model?.empresa.segmento}</Text>
        </Flex>
        <Flex justifyContent={'center'}>
          <ButtonCmp width={'11rem'} OutlineColor={'Rosa'} onClick={() => setModalCropIsOpen(true)}>Trocar logo</ButtonCmp>
        </Flex>
        <hr style={{ border: '0.063rem solid var(--c3)', margin: '1.25rem 0' }}></hr>
        <Flex flexDir={'column'} gap={'.75rem'}>
          <h3>Dados gerais</h3>
          <FormControl alignItems={'center'} as={Flex} gap={'.75rem'}>
            <Flex flexDirection={'column'} justifyContent={'center'} w={'full'}>
              <InputCmp
                label='E-mail'
                placeholder={'empresa@gmail.com.br'}
                type={'text'}
                value={Model?.empresa.email}
                OnChange={(e) => setModel(
                  {
                    ...Model,
                    empresa:
                    {
                      ...Model.empresa,
                      email: e
                    }
                  })}
              />
            </Flex>
            <Flex flexDirection={'column'} justifyContent={'center'} w={'full'}>
              <InputCmp
                label='Telefone'
                placeholder='(xx) xxxx-xxxx'
                type={'text'}
                value={Model?.empresa.telefone}
                OnChange={(e) => setModel(
                  {
                    ...Model,
                    empresa:
                    {
                      ...Model.empresa,
                      telefone: e
                    }
                  })}
              />
            </Flex>
            <Flex flexDirection={'column'} justifyContent={'center'} w={'full'}>
              <InputCmp
                label='Segmento'
                value={Model?.empresa.segmento}
                placeholder='Digite o segmento' type={'text'}
                OnChange={(e) => setModel(
                  {
                    ...Model,
                    empresa:
                    {
                      ...Model.empresa,
                      segmento: e
                    }
                  })}
              />
            </Flex>
            <Flex flexDirection={'column'} justifyContent={'center'} w={'full'}>
              <InputCmp
                label='Número de funcionários'
                value={Model?.empresa.numeroFuncionarios}
                OnChange={(e) => setModel(
                  {
                    ...Model,
                    empresa:
                    {
                      ...Model.empresa,
                      numeroFuncionarios: parseInt(e)
                    }
                  })}
                placeholder='Digite um número'
                type={'number'}
              />
            </Flex>
          </FormControl>

          <hr style={{ border: '0.063rem solid var(--c3)', margin: '1.25rem 0' }} />

          <Flex flexDirection={'column'}>
            <h3>Definição das áreas</h3>
            <small style={{ color: 'var(--c7)' }}>Áreas personalizadas vão constar apenas para demografia em pesquisas pulse e de engajamento e para definir a quantidade de funcionários para controle de adesão das pesquisas.</small>
            <ButtonCmp mt={'.75rem'} leftIcon={<FaPencilAlt />} w={'10rem'} small onClick={() => nav('/Organograma/Departamentos')} VarColor='Green2'>Definir/Editar Áreas</ButtonCmp>
          </Flex>
          <Flex gap={'.75rem'} flexDir={'column'} marginTop={'1rem'}>
            <h3>Quantidade de colaboradores cadastrados por área</h3>
            <Grid gridTemplateColumns={'repeat(5, 1fr)'} gap={'.75rem'}>
              {
                Model?.empresa.areas.map((e, i) => {
                  return (
                      <Flex gap={'.5rem'} key={e.areaId} justifyContent={'start'} alignItems={'center'} border={'2px solid var(--c3)'} p={'.75rem 1rem'}>
                        <Flex color={'var(--Azul)'} alignItems={'center'} gap={'.25rem'} fontSize={'15px'} lineHeight={'17px'} fontWeight={'700'}>
                          {e.qtd}
                          <FaUsers/>
                        </Flex>
                        <Flex alignItems={'center'} fontSize={'13px'} lineHeight={'15px'} fontWeight={'700'}>
                          {e.nome}
                        </Flex>
                      </Flex>
                  )
                })
              }
            </Grid>
          </Flex>

          <hr style={{ border: '0.063rem solid var(--c3)', margin: '1.25rem 0' }}></hr>

          <Flex flexDir={'column'}>
            <h3>Responsáveis pelos chamados do RH</h3>
            <small style={{ color: 'var(--c7)', marginBottom: '.75rem' }}>
              Adicione o e-mail nos quais os chamados com RH devem ser direcionados. Esses usuários serão os únicos que receberam e-mails a cada chamado aberto, porém os administradores ainda teram acesso ao histórico através do sistema.
            </small>
            <MultiColaborador
              colaboradores={Colaboradores}
              selecionados={Model?.responsaveis.filter(r => r.tipoResponsabilidade === 0).map(r => r.colaboradorId) ?? []}
              onChoice={(e) => !PropInvalida(e) && setModel({
                ...Model,
                responsaveis: [...Model.responsaveis, {
                  colaboradorId: e,
                  tipoResponsabilidade: 0
                }]
              })}
              onDelete={(e) => onDeleteResponsavel(0, e)}
            />
          </Flex>
        </Flex>

        <hr style={{ border: '0.063rem solid var(--c3)', margin: '1.25rem 0' }}></hr>

        <h3>Configurações {Model?.nineBoxConfig.tipoMatriz === 0 ? '9Box' : '5Box'}</h3>
        <small style={{ color: 'var(--c7)', marginBottom: '.75rem' }}>
          Configure como será os parâmetros do {Model?.nineBoxConfig.tipoMatriz === 0 ? '9Box' : '5Box'} dentro da empresa.
        </small>
        {/* <SelectCmp
          title='Tipo da matriz'
          OnChange={(r) => setModel(
            {
              ...Model,
              nineBoxConfig:
              {
                ...Model.nineBoxConfig, tipoMatriz: parseInt(r)
              }
            })}
          width={'8rem'}
          mb={'2.3rem'}
        >
          <option value="0">9Box</option>
          <option value="1">5Box</option>
        </SelectCmp> */}
        <FormControl display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'.4rem'} mb={'.75rem'}>
          <CheckboxCmp
            isChecked={Model.nineBoxConfig.liberarBoxGestor}
            OnChange={(r) => setModel(
              {
                ...Model,
                nineBoxConfig:
                {
                  ...Model.nineBoxConfig, liberarBoxGestor: r
                }
              })}>
                Liberar visualização do 9Box para o gestor
          </CheckboxCmp>
        </FormControl>
        <Accordion allowToggle>
          <AccordionItem>
            <AccordionButton _focus={{ boxShadow: 'none !important' }} color={'black'} bg={'var(--White)'} gap={'.3rem'} borderRadius={'1.25rem'} px={'0'}>
              <Flex as='span' textAlign={'left'} fontSize={'.875rem'} fontWeight={'700'} gap={'.2rem'}>
                Definições padrão dos eixos
                <AccordionIcon />
              </Flex>
              <hr style={{ flex: '1', border: '0.063rem solid #333333' }}></hr>
            </AccordionButton>
            <AccordionPanel px={'0'} pb={0}>
              <Grid templateColumns={'repeat(4, 1fr)'} gap={'.75rem'} mb={'1rem'}>
                {
                  Model?.nineBoxConfig.boxes?.map(e => {
                    return (
                      <ItemNineBox
                        key={e.id}
                        box={e}
                      />
                    )
                  })
                }
              </Grid>
              <ButtonCmp onClick={() => nav('NineBox')} VarColor='Rosa' leftIcon={<FaPlus />}>Adicionar mais uma categoria de distribuição e descrição</ButtonCmp>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <hr style={{ border: '0.063rem solid var(--c3)', margin: '1.25rem 0' }}></hr>

        <Flex flexDirection={'column'} gap={'.25rem'} margin={'0 0 1rem 0'} fontSize={'.875rem'}>
          <h4 style={{ display: 'flex' }}>Vigência de contrato até: <Text ml={'.25rem'}>{Model?.contrato}</Text></h4>
          <small style={{ color: 'var(--c7)' }}>Caso o tempo de contrato esteja no fim, entre em contato com seu representante do SAH ou <Link color={'var(--Blue2)'} textDecor='underline' isExternal href='https://arquiteturahumana.com.br/contato/'>clique aqui</Link></small>
        </Flex>

        <Accordion allowToggle>
          <AccordionItem>
            <AccordionButton _focus={{ boxShadow: 'none !important' }} bg={'var(--White)'} color={'var(--Azul)'} gap={'.3rem'} borderRadius={'1.25rem'} px={'0'}>
              <Flex as='span' textAlign={'left'} fontSize={'0.875rem'} gap={'.2rem'}>
                Configurações avançadas
                <AccordionIcon />
              </Flex>
              <hr style={{ border: '0.031rem solid var(--Azul)', flex: '1' }}></hr>
            </AccordionButton>
            <AccordionPanel gap={'.75rem'} px={'0.25rem'} pb={'0'}>
              <FormControl>
                <InputCmp
                  label='Chave de API PI'
                  value={Model.chaveApiPi ?? ''}
                  OnChange={(e) => setModel({ ...Model, chaveApiPi: e })}

                />
              </FormControl>

              <hr style={{ border: '0.063rem solid var(--c3)', margin: '1.25rem 0' }} />

              <Flex flexDir={'column'} gap='.75rem'>
                <h3>Integração teams</h3>
                <FormControl>
                  <InputCmp
                    value={Model.configTeams.tenantId ?? ''}
                    label={'Tenant id'}
                    isInvalid={TeamsIsIvalid}
                    OnChange={(e) => setModel({
                      ...Model,
                      configTeams:
                        { ...Model.configTeams, tenantId: e }
                    })}
                  />
                </FormControl>

                <FormControl>
                  <InputCmp
                    label='Client id'
                    value={Model.configTeams.clientId ?? ''}
                    isInvalid={TeamsIsIvalid}

                    OnChange={(e) => setModel({
                      ...Model,
                      configTeams:
                        { ...Model.configTeams, clientId: e }
                    })}
                  />
                </FormControl>

                <FormControl>
                  <InputCmp
                    isInvalid={TeamsIsIvalid}
                    value={Model.configTeams.clientSecret ?? ''}
                    label='Client secret'
                    OnChange={(e) => setModel({
                      ...Model,
                      configTeams:
                        { ...Model.configTeams, clientSecret: e }
                    })}
                  />
                </FormControl>
              </Flex>

              <hr style={{ border: '0.063rem solid var(--c3)', margin: '1.25rem 0' }} />

              <Flex flexDir={'column'} gap='.75rem'>
                <h3>Integração Zoom</h3>
                <FormControl>
                  <InputCmp
                    isInvalid={ZoomIsIvalid}
                    value={Model.configZoom.accountId ?? ''}
                    label={'Accont id'}
                    OnChange={(e) => setModel({
                      ...Model,
                      configZoom:
                        { ...Model.configZoom, accountId: e }
                    })}
                  />
                </FormControl>

                <FormControl>
                  <InputCmp
                    isInvalid={ZoomIsIvalid}
                    value={Model.configZoom.clientId ?? ''}
                    label='Client id'
                    OnChange={(e) => setModel({
                      ...Model,
                      configZoom:
                        { ...Model.configZoom, clientId: e }
                    })}
                  />
                </FormControl>

                <FormControl>
                  <InputCmp
                    isInvalid={ZoomIsIvalid}
                    value={Model.configZoom.clientSecret ?? ''}
                    label={'Client secret'}
                    OnChange={(e) => setModel({
                      ...Model,
                      configZoom:
                        { ...Model.configZoom, clientSecret: e }
                    })}
                  />
                </FormControl>

                <FormControl>
                  <InputCmp
                    value={Model.configZoom.email ?? ''}
                    label={'Email *'}
                    OnChange={(e) => setModel({
                      ...Model,
                      configZoom:
                        { ...Model.configZoom, email: e }
                    })}
                  />
                </FormControl>
              </Flex>

              <hr style={{ border: '0.063rem solid var(--c3)', margin: '1.25rem 0' }} />

              <Flex flexDir={'column'}>
                <h3>Pesquisa de percepção / desempenho</h3>
                <small style={{ color: 'var(--c7)' }}>Quantidade de colaboradores máximo para adicionar automaticamente ao avaliado</small>

                <Flex flexDirection={'row'} flex='1' gap={'1.25rem'} margin={'.75rem 0 1.25rem 0'}>
                  <Flex alignItems={'center'} gap={'.7rem'}>
                    <InputCmp
                      label='Pares'
                      type={'number'}
                      min={0}
                      w={'3.75rem'}
                      maxHeight={'2rem'}
                      OnChange={(e) => setModel(
                        {
                          ...Model,
                          confiPesquisaPercepcao:
                          {
                            ...Model.confiPesquisaPercepcao, pares: Number.isNaN(parseInt(e)) ? 0 : parseInt(e)
                          }
                        })}
                      value={Model.confiPesquisaPercepcao.pares}>
                    </InputCmp>
                  </Flex>
                  <Flex alignItems={'center'} gap={'.7rem'}>
                    <InputCmp
                      label='Liderados'
                      type={'number'}
                      min={0}
                      w={'3.75rem'}
                      maxHeight={'2rem'}
                      OnChange={(e) => setModel(
                        {
                          ...Model,
                          confiPesquisaPercepcao:
                          {
                            ...Model.confiPesquisaPercepcao, liderados: Number.isNaN(parseInt(e)) ? 0 : parseInt(e)
                          }
                        })}
                      value={Model.confiPesquisaPercepcao.liderados}>
                    </InputCmp>
                  </Flex>
                  <Flex alignItems={'center'} gap={'.7rem'}>
                    <InputCmp
                      label='Outros'
                      type={'number'}
                      min={0}
                      w={'3.75rem'}
                      maxHeight={'2rem'}
                      OnChange={(e) => setModel(
                        {
                          ...Model,
                          confiPesquisaPercepcao:
                          {
                            ...Model.confiPesquisaPercepcao, outros: Number.isNaN(parseInt(e)) ? 0 : parseInt(e)
                          }
                        })}
                      value={Model.confiPesquisaPercepcao.outros}>
                    </InputCmp>
                  </Flex>
                  <Flex alignItems={'center'} gap={'.7rem'}>
                    <InputCmp
                      label='Alta liderança'
                      type={'number'}
                      min={0}
                      w={'3.75rem'}
                      maxHeight={'2rem'}
                      OnChange={(e) => setModel(
                        {
                          ...Model,
                          confiPesquisaPercepcao:
                          {
                            ...Model.confiPesquisaPercepcao, altaLideranca: Number.isNaN(parseInt(e)) ? 0 : parseInt(e)
                          }
                        })}
                      value={Model.confiPesquisaPercepcao.altaLideranca}>
                    </InputCmp>
                  </Flex>
                </Flex>

                <FormControl as={Flex} gap='.75rem' flexDir={'column'}>
                  <CheckboxCmp
                    isChecked={Model?.confiPesquisaPercepcao.liberarResultado}
                    OnChange={(e) => setModel(
                      {
                        ...Model,
                        confiPesquisaPercepcao:
                        {
                          ...Model.confiPesquisaPercepcao, liberarResultado: e
                        }
                      })}>
                        Liberar resultados automaticamente para o avaliado
                  </CheckboxCmp>
                  <CheckboxCmp
                    isChecked={Model?.confiPesquisaPercepcao.monitorarDevolutiva}
                    OnChange={(e) => setModel(
                      {
                        ...Model,
                        confiPesquisaPercepcao:
                        {
                          ...Model.confiPesquisaPercepcao, monitorarDevolutiva: e
                        }
                      })}
                  >
                    Habilitar campo para monitorar o status da devolutiva
                  </CheckboxCmp>
                  <RadioGroup size={'sm'} value={Model.confiPesquisaPercepcao.comentariosResultado.toString()} onChange={(e) => setModel({ ...Model, confiPesquisaPercepcao: { ...Model.confiPesquisaPercepcao, comentariosResultado: parseInt(e) } })}>
                    <Flex flexDir={'column'} mb={'.4rem'} mt={'.25rem'}>
                      <Text fontSize={'16px'} lineHeight='18px' fontWeight={600}>Selecione quem terá acesso para aba de comentários dentro do resultado das pesquisas de Percepção / Desempenho</Text>
                      <small style={{ color: 'var(--c7)' }}>O RH sempre poderá visualizar os comentários da pesquisa</small>
                    </Flex>
                    <Stack direction={'row'} gap={'12px'}>
                      <Radio value={'0'}>Colaboradores</Radio>
                      <Radio value={'1'}>Gestores</Radio>
                      <Radio value={'2'}>Ninguém</Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>

                <Flex flexDir={'column'} my={'1rem'}>
                  <h3>PDI</h3>
                </Flex>
                <Flex flexDir={'column'} gap={'.75rem'}>
                  <CheckboxCmp isChecked={Model.configPDI.colaboradorFazerProprioPDI ?? false} OnChange={(e) => setModel({ ...Model, configPDI: { ...Model.configPDI, colaboradorFazerProprioPDI: e } })}>Colaborador poder atribuir um PDI para si mesmo</CheckboxCmp>
                  <CheckboxCmp isChecked={Model.configPDI.gestorAtribuirPDI ?? false} OnChange={(e) => setModel({ ...Model, configPDI: { ...Model.configPDI, gestorAtribuirPDI: e } })}>Gestor poder atribuir um PDI para seus subordinados</CheckboxCmp>
                </Flex>

                <Flex mt={'1.25rem'} gap={'.75rem'} flexDir={'column'}>
                  <Flex flexDir={'column'}>
                    <h3>Responsáveis por receber os e-mail de conclusão de pesquisa</h3>
                    <small style={{ color: 'var(--c7)' }}>Adicione os e-mails que devem ser notificados quando uma pesquisa de percepção  ou desempenho por competência for finalizada.</small>
                  </Flex>
                  <MultiColaborador
                    colaboradores={Colaboradores}
                    selecionados={Model?.responsaveis.filter(r => r.tipoResponsabilidade === 1).map(r => r.colaboradorId) ?? []}
                    onChoice={(e) => !PropInvalida(e) && setModel({
                      ...Model,
                      responsaveis: [...Model.responsaveis, {
                        colaboradorId: e,
                        tipoResponsabilidade: 1
                      }]
                    })}
                    onDelete={(e) => onDeleteResponsavel(1, e)}
                  />
                  <Flex flexDir={'column'} gap={'.75rem'}>
                    <Flex flexDir={'column'}>
                      <h3 style={{ marginBottom: '-.5rem' }}>Configuração dos relatórios</h3>
                      <Legenda>Defina a configuração padrão para baixar os relatórios conforme o padrão de sua empresa</Legenda>
                    </Flex>
                    <Flex flexDir={'column'} gap={'.75rem'}>
                      <CheckboxCmp isChecked={Model.confiPesquisaPercepcao.comentarios} OnChange={(e) => setModel({ ...Model, confiPesquisaPercepcao: { ...Model.confiPesquisaPercepcao, comentarios: e } })}>Incluir comentários no relatório</CheckboxCmp>
                      <CheckboxCmp isChecked={Model.confiPesquisaPercepcao.assinaturaColaborador} OnChange={(e) => setModel({ ...Model, confiPesquisaPercepcao: { ...Model.confiPesquisaPercepcao, assinaturaColaborador: e } })}>Campo de assinatura do <Text as={'span'} fontWeight={'700'} textDecoration={'underline'} mx={'.25rem'}>colaborador</Text> nas páginas das notas (Gráficos e tabelas)</CheckboxCmp>
                      <CheckboxCmp isChecked={Model.confiPesquisaPercepcao.assinaturaGestor} OnChange={(e) => setModel({ ...Model, confiPesquisaPercepcao: { ...Model.confiPesquisaPercepcao, assinaturaGestor: e } })}>Campo de assinatura do <Text as={'span'} fontWeight={'700'} textDecoration={'underline'} mx={'.25rem'}>gestor</Text> nas páginas das notas (Gráficos e tabelas)</CheckboxCmp>
                      <CheckboxCmp isChecked={Model.confiPesquisaPercepcao.textoConclusao} OnChange={(e) => setModel({ ...Model, confiPesquisaPercepcao: { ...Model.confiPesquisaPercepcao, textoConclusao: e } })}>Aparecer o <Text as={'span'} fontWeight={'700'} textDecoration={'underline'} mx={'.25rem'}>texto de detalhamento de resultado</Text> nos relatórios indiduais de pesquisas quantitativas (Gráficos e tabelas)</CheckboxCmp>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>

              <hr style={{ border: '0.063rem solid var(--c3)', margin: '1.25rem 0' }}></hr>

              <Flex flexDir={'column'} gap={'1rem'}>
                <Flex flexDir={'column'}>
                  <h3>Configurações de trilha</h3>
                  <small style={{ color: 'var(--c7)', marginBottom: '.75rem' }}>Defina usuários que podem editar as trilhas além dos administradores</small>

                  <MultiColaborador
                    colaboradores={Colaboradores}
                    selecionados={Model.responsaveis.filter(e => e.tipoResponsabilidade === 2).map(r => r.colaboradorId)}
                    onChoice={(e) => !PropInvalida(e) && setModel({
                      ...Model,
                      responsaveis: [...Model.responsaveis, {
                        colaboradorId: e,
                        tipoResponsabilidade: 2
                      }]
                    })}
                    onDelete={(e) => onDeleteResponsavel(2, e)}
                  />
                </Flex>

                <Flex flexDir={'column'} gap='1rem'>
                  <h3>Permissões dos gestores</h3>
                  <FormControl as={Flex} gap='.75rem'>
                    <CheckboxCmp
                      isChecked={Model.configTrilha.adicionarEtapaLiderado}
                      OnChange={(e) => setModel(
                        {
                          ...Model,
                          configTrilha:
                          {
                            ...Model.configTrilha, adicionarEtapaLiderado: e
                          }
                        })}
                    >
                      Adicionar etapas a trilha de seus liderados
                    </CheckboxCmp>
                    <CheckboxCmp
                      isChecked={Model.configTrilha.concluirEtapaLiderado}
                      OnChange={(e) => setModel(
                        {
                          ...Model,
                          configTrilha:
                          {
                            ...Model.configTrilha, concluirEtapaLiderado: e
                          }
                        })}
                    >
                      Concluir etapas na trilha de seus liderados
                    </CheckboxCmp>
                    <CheckboxCmp
                      isChecked={Model.configTrilha.editarEtapaLiderado}
                      OnChange={(e) => setModel(
                        {
                          ...Model,
                          configTrilha:
                          {
                            ...Model.configTrilha, editarEtapaLiderado: e
                          }
                        })}
                    >
                      Editar etapas na trilha de seus liderados
                    </CheckboxCmp>
                  </FormControl>
                </Flex>
              </Flex>

              <hr style={{ border: '0.063rem solid var(--c3)', margin: '1.25rem 0' }}></hr>

              <Flex gap={'.75rem'} flexDir={'column'}>
                <h3>Configurações reunião 1:1</h3>

                <Flex fontSize={'.875rem'} gap={'.25rem'} flexWrap='wrap' flexDir={'column'}>
                  <Text fontWeight={'600'}>Tipos de reunião cadastrados:</Text>
                  <Flex gap={'.5rem'} flexWrap='wrap'>
                    {Tipos.map((tipo, i) => {
                      return (
                        <Tag
                          key={i}
                          isDisabled={i <= 2}
                          onDelete={() => onOpenModalDelete(tipo.id)}
                          onUpdate={AtualizarTipo}
                          model={tipo}
                        />
                      )
                    })}
                  </Flex>
                </Flex>

                <Flex flexDir={'column'} gap={'.75rem'}>
                  <h4>Quem pode adicionar novos tipos de reunião?</h4>
                  <Flex gap={'.75rem'}>
                    <FormControl width={'auto'} alignItems={'center'} as={Flex} gap='.5rem'>
                      <CheckboxCmp
                        isChecked={Model.configReuniao.responsaveisAdicionarTipo.includes(2)}
                        OnChange={() =>
                          setModel({
                            ...Model,
                            configReuniao: {
                              ...Model.configReuniao,
                              responsaveisAdicionarTipo: Model.configReuniao.responsaveisAdicionarTipo.includes(2)
                                ? [...Model.configReuniao.responsaveisAdicionarTipo.filter(e => e !== 2)]
                                : [...Model.configReuniao.responsaveisAdicionarTipo, 2]
                            }
                          })
                        }
                      >
                        Gestores de área
                      </CheckboxCmp>
                    </FormControl>

                    <FormControl width={'auto'} alignItems={'center'} as={Flex} gap='.5rem'>
                      <CheckboxCmp
                        isChecked={Model.configReuniao.responsaveisAdicionarTipo.includes(1)}
                        OnChange={() =>
                          setModel({
                            ...Model,
                            configReuniao: {
                              ...Model.configReuniao,
                              responsaveisAdicionarTipo: Model.configReuniao.responsaveisAdicionarTipo.includes(1)
                                ? [...Model.configReuniao.responsaveisAdicionarTipo.filter(e => e !== 1)]
                                : [...Model.configReuniao.responsaveisAdicionarTipo, 1]
                            }
                          })
                        }
                      >
                        Gestores
                      </CheckboxCmp>
                    </FormControl>
                  </Flex>
                  <Flex flexDir={'column'}>
                    <h4>Outros</h4>
                    <small
                      style={{ color: 'var(--c7)' }}
                    >Além dos gestores você pode liberar para colaboradores expecificos adicionando-os abaixo</small>
                  </Flex>
                  <MultiColaborador
                    colaboradores={Colaboradores}
                    selecionados={Model.responsaveis.filter(e => e.tipoResponsabilidade === 3).map(r => r.colaboradorId)}
                    onChoice={(e) => !PropInvalida(e) && setModel({
                      ...Model,
                      responsaveis: [...Model.responsaveis, {
                        colaboradorId: e,
                        tipoResponsabilidade: 3
                      }]
                    })}
                    onDelete={(e) => onDeleteResponsavel(3, e)}
                  />
                </Flex>

                <Flex flexDir={'column'} mt={'.25rem'} gap={'.25rem'}>
                  <h4>Com quem o usuário pode solicitar reunião?</h4>

                  <Flex gap={'.75rem'}>
                    <FormControl width={'auto'} alignItems={'center'} as={Flex} gap='.5rem'>
                      <CheckboxCmp
                        isChecked={Model.configReuniao.tipoListaParticipante.includes(0)}
                        isDisabled={Model.configReuniao.tipoListaParticipante.includes(3)}
                        OnChange={() =>
                          setModel({
                            ...Model,
                            configReuniao: {
                              ...Model.configReuniao,
                              tipoListaParticipante: Model.configReuniao.tipoListaParticipante.includes(0)
                                ? [...Model.configReuniao.tipoListaParticipante.filter(e => e !== 0)]
                                : [...Model.configReuniao.tipoListaParticipante, 0]
                            }
                          })
                        }
                      >
                        Seu gestor
                      </CheckboxCmp>
                    </FormControl>

                    <FormControl width={'auto'} alignItems={'center'} as={Flex} gap='.5rem'>
                      <CheckboxCmp
                        isChecked={Model.configReuniao.tipoListaParticipante.includes(1)}
                        isDisabled={Model.configReuniao.tipoListaParticipante.includes(3)}
                        OnChange={() =>
                          setModel({
                            ...Model,
                            configReuniao: {
                              ...Model.configReuniao,
                              tipoListaParticipante: Model.configReuniao.tipoListaParticipante.includes(1)
                                ? [...Model.configReuniao.tipoListaParticipante.filter(e => e !== 1)]
                                : [...Model.configReuniao.tipoListaParticipante, 1]
                            }
                          })
                        }
                      >
                        Seus pares
                      </CheckboxCmp>
                    </FormControl>

                    <FormControl width={'auto'} alignItems={'center'} as={Flex} gap='.5rem'>
                      <CheckboxCmp
                        isDisabled={Model.configReuniao.tipoListaParticipante.includes(3)}
                        isChecked={Model.configReuniao.tipoListaParticipante.includes(2)}
                        OnChange={() =>
                          setModel({
                            ...Model,
                            configReuniao: {
                              ...Model.configReuniao,
                              tipoListaParticipante: Model.configReuniao.tipoListaParticipante.includes(2)
                                ? [...Model.configReuniao.tipoListaParticipante.filter(e => e !== 2)]
                                : [...Model.configReuniao.tipoListaParticipante, 2]
                            }
                          })
                        }
                      >
                        Seus liderados
                      </CheckboxCmp>
                    </FormControl>

                    <FormControl width={'auto'} alignItems={'center'} as={Flex} gap='.5rem'>
                      <CheckboxCmp
                        isChecked={Model.configReuniao.tipoListaParticipante.includes(3)}
                        OnChange={() =>
                          setModel({
                            ...Model,
                            configReuniao: {
                              ...Model.configReuniao,
                              tipoListaParticipante: Model.configReuniao.tipoListaParticipante.includes(3)
                                ? [...Model.configReuniao.tipoListaParticipante.filter(e => e !== 3)]
                                : [...Model.configReuniao.tipoListaParticipante, 3]
                            }
                          })
                        }
                      >
                        Todos os colaboradores
                      </CheckboxCmp>
                    </FormControl>

                  </Flex>
                </Flex>

                <Flex my={'.5rem'} flexDir={'column'} gap={'.25rem'}>
                  <h4>Quem pode criar reuniões 1:1 para os outros colaboradores?</h4>
                  <MultiColaborador
                    colaboradores={Colaboradores}
                    selecionados={Model.responsaveis.filter(e => e.tipoResponsabilidade === 4).map(r => r.colaboradorId)}
                    onChoice={(e) => !PropInvalida(e) && setModel({
                      ...Model,
                      responsaveis: [...Model.responsaveis, {
                        colaboradorId: e,
                        tipoResponsabilidade: 4
                      }]
                    })}
                    onDelete={(e) => onDeleteResponsavel(4, e)}
                  />
                </Flex>

                <Flex flexDir={'column'} gap={'.75rem'}>
                  <Flex>
                    <ButtonCmp onClick={() => nav('Feedback/Formularios')} VarColor='Rosa'>Administrar formulários de feedback</ButtonCmp>
                  </Flex>

                  <Flex flexDir={'column'} gap={'.5rem'}>
                    <h4>Quem pode criar formulários de feedbacks para os outros colaboradores na reunião 1:1?</h4>
                    <Flex gap={'.75rem'}>
                      <FormControl width={'auto'} alignItems={'center'} as={Flex} gap='.5rem'>
                        <CheckboxCmp

                          isChecked={Model.configReuniao.formularioFeedback.includes(0)}
                          OnChange={() =>
                            setModel({
                              ...Model,
                              configReuniao: {
                                ...Model.configReuniao,
                                formularioFeedback: Model.configReuniao.formularioFeedback.includes(0)
                                  ? [...Model.configReuniao.formularioFeedback.filter(e => e !== 0)]
                                  : [...Model.configReuniao.formularioFeedback, 0]
                              }
                            })
                          }
                        >
                          Colaboradores
                        </CheckboxCmp>
                      </FormControl>

                      <FormControl width={'auto'} alignItems={'center'} as={Flex} gap='.5rem'>
                        <CheckboxCmp
                          isChecked={Model.configReuniao.formularioFeedback.includes(1)}
                          OnChange={() =>
                            setModel({
                              ...Model,
                              configReuniao: {
                                ...Model.configReuniao,
                                formularioFeedback: Model.configReuniao.formularioFeedback.includes(1)
                                  ? [...Model.configReuniao.formularioFeedback.filter(e => e !== 1)]
                                  : [...Model.configReuniao.formularioFeedback, 1]
                              }
                            })
                          }
                        >
                          Gestores
                        </CheckboxCmp>
                      </FormControl>

                      <FormControl width={'auto'} alignItems={'center'} as={Flex} gap='.5rem'>
                        <CheckboxCmp
                          isChecked={Model.configReuniao.formularioFeedback.includes(2)}
                          OnChange={() =>
                            setModel({
                              ...Model,
                              configReuniao: {
                                ...Model.configReuniao,
                                formularioFeedback: Model.configReuniao.formularioFeedback.includes(2)
                                  ? [...Model.configReuniao.formularioFeedback.filter(e => e !== 2)]
                                  : [...Model.configReuniao.formularioFeedback, 2]
                              }
                            })
                          }
                        >
                          Gestores de área
                        </CheckboxCmp>
                      </FormControl>
                    </Flex>
                  </Flex>
                  <Flex flexDir={'column'}>
                    <h4>Outros</h4>
                    <small
                      style={{ color: 'var(--c7)' }}
                    >Além das opções acima você pode liberar para colaboradores expecificos adicionando-os abaixo</small>
                  </Flex>
                  <MultiColaborador
                    colaboradores={Colaboradores}
                    selecionados={Model.responsaveis.filter(e => e.tipoResponsabilidade === 5).map(r => r.colaboradorId)}
                    onChoice={(e) => !PropInvalida(e) && setModel({
                      ...Model,
                      responsaveis: [...Model.responsaveis, {
                        colaboradorId: e,
                        tipoResponsabilidade: 5
                      }]
                    })}
                    onDelete={(e) => onDeleteResponsavel(5, e)}
                  />
                </Flex>
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Flex justifyContent={'end'} marginTop={'.5rem'}>
          <ButtonCmp
            leftIcon={<FaSave />}
            onClick={AtualizarConfiguracao}
            VarColor='Green2'
            isLoading={IsLoading}
          >Salvar alterações</ButtonCmp>
        </Flex>
      </WhiteContainer>
    </Body>
  )
}
