import { Flex, Text } from '@chakra-ui/react'
import { HTMLAttributes } from 'react'
import { FaSignal } from 'react-icons/fa'
import { iEscala, iRespostaEscala } from '../../interfaces'
import { parseJwt } from '../../services/token'
import { Container } from './styles'
interface iBoxProps extends HTMLAttributes<HTMLDivElement>{
  Selecionado?: boolean
  Escala: iEscala
  jaSelecionada?: string
  escalaPesquisa?: string
}

export const BoxEscala: React.FC<iBoxProps> = ({ Selecionado, Escala, onClick, jaSelecionada, escalaPesquisa }) => {
  return (
        <Container
            active={Selecionado}
            jaSelecionada={jaSelecionada === Escala.id}
            onClick={onClick}
        >
            <FaSignal size={20} />
            <Flex flexDir={'column'} gap={'.25rem'}>
                <Flex flexDir={'column'}>
                  <Flex alignItems={'center'} gap={'.4rem'} as='h3'>{Escala.nome} {jaSelecionada === Escala.id ? <Flex as='span' color={'var(--Green2)'}>(Já selecionado)</Flex> : ''}</Flex>
                  {escalaPesquisa && escalaPesquisa === Escala.id && <Text fontSize={'12px'} color={'var(--Gray2)'} lineHeight={'12px'}>Escala padrão da pesquisa</Text>}
                </Flex>
                {parseJwt().eid !== '02e3591d-d8c0-42e9-8aba-2c0cdcc3e034' &&
                <ul>
                    {
                      Escala.respostas.map((e: iRespostaEscala, i) => {
                        return (
                          <li key={i}>{e.resposta} {i !== Escala.respostas.length - 1 ? `( ${e.peso} )` : ''}</li>
                        )
                      })
                    }
                </ul>}

                {parseJwt().eid === '02e3591d-d8c0-42e9-8aba-2c0cdcc3e034' &&
                <ul>
                    {
                      Escala.respostas.map((e: iRespostaEscala, i) => {
                        return (
                          <li key={i}>{e.resposta} {`( ${e.peso} )`}</li>
                        )
                      })
                    }
                </ul>}
            </Flex>
        </Container>
  )
}
