/* eslint-disable no-prototype-builtins */
import { useLocation } from 'react-router-dom'
import { iEtapa, iGroupByExtensions, iGroupData, iGroupDataList } from '../interfaces'
import { parseJwt } from '../services/token'

export function ObterCorEtapaPeloTipo(Tipo: number): string {
  let cor = ''
  switch (Tipo) {
    case 0:
      cor = '67C0A2'
      break

    case 1:
      cor = 'B265FF'
      break

    case 2:
      cor = '00B496'
      break

    case 3:
      cor = '7879F1'
      break

    case 4:
      cor = '6DA0FF'
      break

    case 5:
      cor = 'EC9C00'
      break

    case 6:
      cor = '00B45A'
      break

    case 7:
      cor = '365ad0'
      break

    case 8:
      cor = 'EB5757'
      break

    case 9:
      cor = 'E18787'
      break

    case 10:
      cor = '365ad0'
      break
    default:return 'C9C9C9'
  }
  return cor
}

export function ObterPercentual(qtdObservada: number, total: number): number {
  return qtdObservada * 100 / total
}

export function ObterCorFundoEtapaPeloTipo(Tipo: number): string {
  let cor = ''
  switch (Tipo) {
    case 0:
      cor = 'DBF0E9'
      break

    case 1:
      cor = 'F6EDFF'
      break

    case 2:
      cor = 'E6FEFA'
      break

    case 3:
      cor = 'F6EDFF'
      break

    case 4:
      cor = 'F0F5FE'
      break

    case 5:
      cor = 'F9F3DC'
      break

    case 6:
      cor = 'c9F3DC'
      break

    case 7:
      cor = 'adbcf7'
      break
    case 9:
      cor = 'E187874D'
      break
    case 10:
      cor = '1961E00D'
      break
    default: return 'white'
  }
  return cor
}

export function ObterToken(): string {
  const token = localStorage.getItem('token')
  return `bearer ${token as string}`
}

export function DistinctString(array: string[]): string[] {
  return array.filter((value, index, array) => array.indexOf(value) === index)
}

export function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search)
}

export function ValidarEtapa(Etapa: iEtapa): boolean {
  if (PropInvalida(Etapa)) {
    return false
  }

  if (PropInvalida(Etapa.dataInicio) && PropInvalida(Etapa.dataFim) && PropInvalida(Etapa.etapaPai) && PropInvalida(Etapa.tempoDuracao) && PropInvalida(Etapa.tempoInicio) && PropInvalida(Etapa.tempoAntesDesligamento) && PropInvalida(Etapa.tempoAposDesligamento)) {
    return false
  }

  if (PropInvalida(Etapa.dataInicio) && PropInvalida(Etapa.dataFim) && PropInvalida(Etapa.etapaPai) && (PropInvalida(Etapa.tempoDuracao) || (PropInvalida(Etapa.tempoInicio) && PropInvalida(Etapa.tempoAntesDesligamento) && PropInvalida(Etapa.tempoAposDesligamento)))) {
    return false
  }

  return true
}

export function ValidarSenha(senha: string): boolean {
  return /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.{6,})/.test(senha)
}

export function ValidarEmail(email: string): boolean {
  if (email === undefined || email === null || email.length > 120 || email.length < 5 || !email.includes('@')) {
    return false
  }

  return true
}

export function ValidaCPF(cpf: string): boolean {
  cpf = cpf.replace(/[\s.-]*/igm, '')
  if (
    !cpf ||
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  ) {
    return false
  }
  let soma = 0
  let resto
  for (let i = 1; i <= 9; i++) { soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i) }
  resto = (soma * 10) % 11
  if ((resto === 10) || (resto === 11)) resto = 0
  if (resto !== parseInt(cpf.substring(9, 10))) return false
  soma = 0
  for (let i2 = 1; i2 <= 10; i2++) { soma = soma + parseInt(cpf.substring(i2 - 1, i2)) * (12 - i2) }
  resto = (soma * 10) % 11
  if ((resto === 10) || (resto === 11)) resto = 0
  if (resto !== parseInt(cpf.substring(10, 11))) return false

  return true
}

export function DeleteElementByPosition<T>(array: T[], index: number): T[] {
  array.splice(index, 1)
  return array
}

export function InsertElementByPosition<T>(arr: T[], index: number, newItem: T): T[] {
  return [...arr.slice(0, index), newItem, ...arr.slice(index)]
}

export function ArrayToChunks<T>(array: T[], size: number): T[][] {
  const chunks: T[][] = []
  for (let i = 0; i < array.length; i += size) {
    const chunk = array.slice(i, i + size)
    chunks.push(chunk)
  }
  return chunks
}

export function PropInvalida(...args: any[]): boolean {
  return args.some(arg => arg === '' || arg == null || arg === undefined || arg === 'NaN')
}

export function IsEq<T>(alvo: T, ...args: T[]): boolean {
  return args.some(arg => arg === alvo)
}

export const ObjetoVazio = (objectName: any): boolean => {
  return objectName && Object.keys(objectName).length === 0
}

export function converterData(val: string, plusDay?: number): Date {
  const [day, month, year] = val.split('/')
  if (plusDay) {
    const date = new Date(+year, +month - 1, +day + plusDay)
    return date
  } else {
    const date = new Date(+year, +month - 1, +day)
    return date
  }
}

export function ConverterDataHora(val: string, plusDay?: number): Date {
  const [day, month, yearComHora] = val.split('/')
  const year = yearComHora.split(' ')[0]
  const [Hours, Minutes] = yearComHora.split(' ')[1].split(':')

  if (plusDay) {
    return new Date(+year, +month - 1, +day + plusDay, +Hours, +Minutes)
  } else {
    return new Date(+year, +month - 1, +day, +Hours, +Minutes)
  }
}

export function UpdateElementByPosition<T>(arr: T[], index: number, upItem: T): T[] {
  const copy = [...arr]
  copy[index] = upItem
  return copy
}

export function transformarDuracao(tempo: number): string {
  const horas = (tempo / 60).toString().split('.')[0]
  const minutos = tempo % 60
  const str = `${horas} ${parseInt(horas) > 1 ? 'horas' : 'hora'} ${minutos > 0 ? `e ${minutos} minutos` : ''}`
  if (str.includes('0 hora')) {
    return str.replace('0 hora e', '')
  }
  return str
}

export function SubstringRoute(route: string, stop: string): string {
  return route.split(stop)[0]
}

export function AutorizarPI(): boolean {
  const jwt = parseJwt()
  const empresa = jwt.eid === 'acbf6a22-154e-480d-a465-61b990faa8bd' || jwt.eid === 'c5d7423c-5fbc-4917-a667-c2a2ec9693d7'
  const consultoria = jwt.consultoria === 'True'
  return empresa || jwt.uid === 'd87c1f61-9d40-4d38-ad30-ff4362a65360' || jwt.uid === 'b6834d5c-a101-4551-a37f-696b10965a66' ||
  jwt.uid === '94dc7859-af8c-49c6-8673-549ccd05d54e' || consultoria || jwt.uid === '42fda8a8-b889-45dc-ba17-78a0a1a81897'
}

export function AutorizarAHPI(): boolean {
  const jwt = parseJwt()
  return jwt.uid === 'd87c1f61-9d40-4d38-ad30-ff4362a65360' || jwt.uid === 'b6834d5c-a101-4551-a37f-696b10965a66' ||
  jwt.uid === '94dc7859-af8c-49c6-8673-549ccd05d54e' || jwt.uid === '42fda8a8-b889-45dc-ba17-78a0a1a81897'
}

export function FluxoEtapa(rota: string): boolean {
  rota = rota.toLowerCase()
  return rota.includes('processo') || rota.includes('trilha')
}

export function IsLink(text: string): boolean {
  return text.includes('https') || text.includes('www') || text.includes('.com')
}

export function GroupBy<T>(data: T[], getKey: (item: T) => string|number): iGroupByExtensions<T> {
  const extension: iGroupByExtensions<T> = {
    default: function() {
      return data.reduce((acc: iGroupData<T>, obj) => {
        const key = getKey(obj)

        // Se a chave não existir no acumulador, crie-a como um array vazio
        if (!acc[key]) {
          acc[key] = []
        }

        // Adicione o objeto ao grupo apropriado
        acc[key].push(obj)

        return acc
      }, {})
    },
    toList: function() {
      const arr = data.reduce((acc: iGroupData<T>, obj) => {
        const key = getKey(obj)
        // Se a chave não existir no acumulador, crie-a como um array vazio
        if (!acc[key]) {
          acc[key] = []
        }
        // Adicione o objeto ao grupo apropriado
        acc[key].push(obj)

        return acc
      }, {})

      const arr2: Array<iGroupDataList<T>> = []
      for (const key in arr) {
        if (arr.hasOwnProperty(key)) {
          const group = arr[key]
          arr2.push({
            key: key,
            data: group
          })
        }
      }
      return arr2
    }
  }

  return extension
}

export function HSelect<T, U>(data: T[], getKey: (item: T) => U, distinct: boolean): U[] {
  if (distinct) {
    const result: U[] = []
    for (const item of data) {
      const key = getKey(item)
      if (!result.some(e => JSON.stringify(e) === JSON.stringify(key)) && !PropInvalida(key)) {
        result.push(key)
      }
    }
    return result
  } else {
    return data.map(getKey)
  }
}

export function ReturnUniqueByKey(data: any[], key: string, orderBy?: string): any[] {
  let result: any[] = []
  for (const item of data) {
    if (!result.some(e => e[key] === item[key]) && item[key] !== null) {
      result.push(item)
    }
  }
  if (orderBy) {
    result = result.sort((a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1
      }
      if (a[orderBy] > b[orderBy]) {
        return 1
      }
      return 0
    })
  }
  return result
}

export function HSelectMany<T, U>(array: T[], selector: (item: T) => U[]): U[] {
  return ([] as U[]).concat(...array.map(selector))
}

export const DownloadImage = (base64: string, filename?: string, extension?: string): any => {
  const base64Image = base64

  const binaryData = atob(base64Image.split(',')[1])
  const byteArray = new Uint8Array(binaryData.length)

  for (let i = 0; i < binaryData.length; i++) {
    byteArray[i] = binaryData.charCodeAt(i)
  }

  const blob = new Blob([byteArray], { type: 'image/png' })
  const blobUrl = URL.createObjectURL(blob)

  const downloadLink = document.createElement('a')
  downloadLink.href = blobUrl
  downloadLink.download = `${filename ?? 'imagem'}.${extension ?? 'png'}`
  downloadLink.style.display = 'none'

  document.body.appendChild(downloadLink)
  downloadLink.click()

  document.body.removeChild(downloadLink)
  URL.revokeObjectURL(blobUrl)
}

export function AdicionarDia(dataString: string, dias: number, minutos?: number): string {
  const dataAtual = new Date(dataString)
  dataAtual.setDate(dataAtual.getDate() + dias)
  const ano = dataAtual.getFullYear()
  const mes = String(dataAtual.getMonth() + 1).padStart(2, '0')
  const dia = String(dataAtual.getDate()).padStart(2, '0')
  const hora = String(dataAtual.getHours()).padStart(2, '0')
  const minuto = String(dataAtual.getMinutes() + (minutos ?? 1)).padStart(2, '0')

  const novaDataString = `${ano}-${mes}-${dia}T${hora}:${minuto}`
  return novaDataString
}

export function FormatarCompetencia(competencia: string): string {
  let palavras = competencia.toLocaleLowerCase().split(' ')
  if (palavras.length === 1) {
    palavras = competencia.replace('/', ' / ').split(' ')
  }
  for (let i = 0; i < palavras.length; i++) {
    if (palavras[i][0] !== undefined) {
      palavras[i] = palavras[i][0].toUpperCase() + palavras[i].substr(1)
    }
  }
  return palavras.join(' ')
}

export function sleep(ms: number): void {
  const startTime = new Date().getTime()
  while (new Date().getTime() - startTime < ms) { /* empty */ }
}

export async function sleepAsync(ms: number): Promise<void> {
  return await new Promise(resolve => setTimeout(resolve, ms))
}

export function formatarData(data: string): string {
  const dataStr = data.split('T')[0]
  const dataArr = dataStr.split('-')
  return `${dataArr[2]}/${dataArr[1]}/${dataArr[0]}`
}

export function returnDate(dataString: string): Date {
  const partes = dataString.split('/')
  const dataFormatada = partes[2] + '-' + partes[1] + '-' + partes[0]
  return new Date(dataFormatada)
}

export function obterDataParaCsv(): string {
  const data = new Date()
  const dia = String(data.getDate()).padStart(2, '0')
  const mes = String(data.getMonth() + 1).padStart(2, '0')
  const ano = data.getFullYear()
  const horas = String(data.getHours()).padStart(2, '0')
  const minutos = String(data.getMinutes()).padStart(2, '0')

  return `${dia}-${mes}-${ano}-${horas}-${minutos}`
}

export function FormatarMensagemEditorDeTexto(texto: string): string {
  return texto.split('<p>').join('<p style="line-height: 200%">').split('<p style="line-height: 200%"></p>').join('<br>').split('<h1>').join('<h1 style="line-height: 200%">').split('<h1>').join('<h1 style="line-height: 200%">').split('<h2>').join('<h2 style="line-height: 200%">').split('<h3>').join('<h3 style="line-height: 230%">').split('<h4>').join('<h4 style="line-height: 250%">').split('<h5>').join('<h5 style="line-height: 300%">').split('<h6>').join('<h6 style="line-height: 300%">').split('<ul>').join('<ul style="padding-left: 16px">').split('<ol>').join('<ol style="padding-left: 16px">')
}
